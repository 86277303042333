import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import styled from 'styled-components';
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import RecorderPanel from "../recorder/panels/RecorderPanel";

export default function IndexApp(props) {


    return (
        <Wrapper>
            <RecorderPanel/>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;
`;
