import logo from './logo.svg';
import './App.css';
import IndexApp from "./components/apps/IndexApp";

function App() {
  return (
    <IndexApp/>
  );
}

export default App;
