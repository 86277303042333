import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import styled from 'styled-components';
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import VideoRecorder from 'react-video-recorder'

function sendUrlToParent(url) {
    try {
        window.parent.postMessage({
            'func': 'videoRecordHandler',
            'message': url
        }, "*");
        // parent.videoRecordHandler(url);
    } catch (exc) {

    }
}

async function saveBlob(blob, onProgress = () => {

}) {
    let file = blob;
    const fd = new FormData();
    fd.append('file', file);
    let pld = (await axios.post('https://files.entsu.ru/users-file/upload', fd, {
        onUploadProgress: progressEvent => {
            let {loaded, total} = progressEvent;
            let progress = 100.0 * (+loaded) / (+total);
            console.log('progress: ', progress);
            onProgress({progress, loaded, total});
        }
    })).data;
    console.log('pld = ', pld);
    return pld;
}

export default function RecorderPanel(props) {
    const [saving, setSaving] = useState(false);

    const [hasBlob, setHasBlob] = useState(false);

    const blobRef = useRef();

    return (
        <Wrapper>
            <TopPlaceholder>
                <VideoRecorder
                    onRecordingComplete={async (videoBlob) => {
                        console.log('videoBlob', videoBlob);
                        blobRef.current = videoBlob;
                        setHasBlob(true);
                    }}
                    onStartRecording={() => {
                        setHasBlob(false);
                        blobRef.current = undefined;
                    }}
                />
            </TopPlaceholder>

            {hasBlob == false || saving == true ? null :
                <BottomPlaceholder>

                    <Button onClick={async () => {
                        setSaving(true);
                        let pld = await saveBlob(blobRef.current);
                        sendUrlToParent(pld.url);
                        setSaving(false);
                    }}>
                        SAVE
                    </Button>

                    {/*Saving... Please wait. It can take up to 1-2 minutes...*/}
                </BottomPlaceholder>
            }

            {saving == false ? null :
                <BottomPlaceholder>
                    Saving... Please wait. It can take up to 1-2 minutes...
                </BottomPlaceholder>
            }


        </Wrapper>
    );
}

const Button = styled.div`
    padding-left: 25px;
    padding-right: 25px;
    height: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: blue;
    opacity: 0.8;
    border-radius: 4px;
    cursor: pointer;
    color: white;
    :hover{
      opacity: 1;
    }
`;

const Wrapper = styled.div`
    width: 100%;  
    height: 100%;
    box-sizing: border-box; 
    display: flex;
    flex-direction: column;
    align-items: stretch;
`;

const TopPlaceholder = styled.div`
    flex: 1;
`;

const BottomPlaceholder = styled.div`
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 20px;
`;
